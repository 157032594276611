import { Flex } from '@stars-ecom/shared-atoms-ui'
import { AccountLayout, AccountOrderHistory } from '@stars-ecom/shared-organisms-ui'
import React from 'react'
import { Helmet } from 'react-helmet'

import { siteFormatter } from '../utils/metaTitleFormatter'

const AccountOrderHistoryPage = (props) => {
  const { pageContext, location } = props
  const { trackings, loyaltyCard } = pageContext

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      pb="15px"
      backgroundColor="#f6f3f1"
      w="100%">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content={`Mes commandes - ${siteFormatter(process.env.GATSBY_API_WEBSITE)}`}
        />
        <title>{`Mes commandes - ${siteFormatter(process.env.GATSBY_API_WEBSITE)}`}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <AccountLayout>
        <AccountOrderHistory
          trackings={trackings}
          orderCode={location?.hash}
          loyaltyCard={loyaltyCard}
        />
      </AccountLayout>
    </Flex>
  )
}

AccountOrderHistoryPage.pageType = 'account'

export default AccountOrderHistoryPage
